import {
  default as CameraPhoto,
  CaptureConfigOption,
  default as LibCameraPhoto,
} from '@/lib/jslib-html5-camera-photo/index.js'

import { useLocalStorage, useOs } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import { captureException } from '@sentry/react'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { openFirstTimeCameraModal, openGenericCameraErrorModal, openPermissionDeniedModal } from './CameraModals'
import shutterSound from '/camera-shutter.mp3'

type FacingMode = 'user' | 'environment'
const shutterAudio = new Audio(shutterSound)

interface Resolution {
  height?: MediaTrackConstraints['height'] | undefined
  width?: MediaTrackConstraints['width'] | undefined
}

export enum CameraState {
  READY = 'READY',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

type StateProps = {
  devices: MediaDeviceInfo[]
  cameraState: CameraState
  cameraStartError: Error | null
  currentDeviceId: string | undefined
}

interface UseLibCameraPhotoProps {
  deviceId?: string
  idealFacingMode: FacingMode
  idealResolution?: Resolution
  isMaxResolution?: boolean
  enabled?: boolean
  showGenericError?: boolean
  overwritePermissionError?: boolean
  /**
   * Key to use for the first time camera modal.
   */
  firstTimeKey?: string
  /**
   * Callback function to handle camera errors.
   * @param error The error object thrown by the camera
   * @remarks Should be wrapped in useCallback to prevent infinite re-renders
   */
  onError?: (error: Error) => void
}

export function useLibCameraPhoto({
  deviceId,
  idealFacingMode,
  idealResolution,
  isMaxResolution,
  enabled = true,
  showGenericError = false,
  overwritePermissionError = false,
  firstTimeKey,
  onError,
}: UseLibCameraPhotoProps) {
  const os = useOs({
    getValueInEffect: false,
  })
  const isIos = os === 'ios'

  const [isFirstTime, setIsFirstTime] = useLocalStorage<boolean | null>({
    key: firstTimeKey || '',
    defaultValue: true,
    getInitialValueInEffect: false,
  })
  const [state, setState] = useState<StateProps>({
    devices: [],
    cameraState: CameraState.LOADING,
    cameraStartError: null,
    currentDeviceId: undefined,
  })

  const iosRejectCount = useRef(0)
  const mediaStreamRef = useRef<MediaStream | null>(null)
  const libCameraPhotoRef = useRef<CameraPhoto | null>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const handleCameraError = useCallback(
    (_error: unknown, enableStream: () => Promise<void>) => {
      const error = _error as Error

      // toast.error(`${error.name}: ${error.message}`)

      if (error?.name === 'NotAllowedError' && !overwritePermissionError && iosRejectCount.current <= 1) {
        openPermissionDeniedModal({
          onSubmit: async () => {
            if (isIos) {
              iosRejectCount.current += 1
            }
            enableStream()
            modals.closeAll()
          },
        })
        return
      }

      if (onError && typeof onError === 'function') {
        onError(error)
      }

      if ((isIos && iosRejectCount.current > 1 && typeof onError !== 'function') || showGenericError) {
        openGenericCameraErrorModal()
      }

      setState((prev) => ({ ...prev, cameraState: CameraState.ERROR, cameraStartError: error as Error }))
      captureException('Error starting camera', {
        level: 'error',
        extra: {
          error: {
            name: (error as Error)?.name,
            message: (error as Error)?.message,
          },
          description: 'Error starting camera',
          isMaxResolution,
          idealFacingMode,
          idealResolution,
        },
      })
    },
    [isIos, overwritePermissionError, onError, showGenericError, isMaxResolution, idealFacingMode, idealResolution],
  )

  const enableStream = useCallback(async () => {
    if (firstTimeKey && isFirstTime === true) {
      openFirstTimeCameraModal({
        onSubmit: () => {
          setIsFirstTime(false)
          modals.closeAll()
        },
      })
      return
    }

    if (!libCameraPhotoRef.current || !enabled) return

    setState((prev) => ({ ...prev, cameraState: CameraState.LOADING }))
    try {
      let _mediaStream = null

      if (isMaxResolution) {
        _mediaStream = await libCameraPhotoRef.current.startCameraMaxResolution(deviceId ?? idealFacingMode)
      } else {
        _mediaStream = await libCameraPhotoRef.current.startCamera(deviceId ?? idealFacingMode, idealResolution)
      }

      if (_mediaStream) {
        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach((track) => track.stop())
        }
        mediaStreamRef.current = _mediaStream

        const devices = await libCameraPhotoRef.current.mediaDevices?.enumerateDevices()
        const currentDeviceId = _mediaStream.getVideoTracks()?.[0]?.getSettings()?.deviceId

        setState((prev) => ({
          ...prev,
          cameraState: CameraState.READY,
          cameraStartError: null,
          devices: devices?.filter((el) => el.kind === 'videoinput') ?? [],
          currentDeviceId,
        }))
      }
    } catch (_error) {
      console.error('error', _error, _error instanceof Error ? `${_error.name}: ${_error.message}` : '')
      handleCameraError(_error, enableStream)
    }
  }, [
    deviceId,
    firstTimeKey,
    isFirstTime,
    enabled,
    setIsFirstTime,
    isMaxResolution,
    idealFacingMode,
    idealResolution,
    handleCameraError,
  ])

  const stopStream = useCallback(async () => {
    if (libCameraPhotoRef.current) {
      try {
        await libCameraPhotoRef.current.stopCamera()
      } catch (error) {
        // console.warn("Camera couldn't stop")
      }
    }

    // Just to be sure also stop the stream manually
    if (!mediaStreamRef.current) return

    mediaStreamRef.current.getTracks().forEach((track) => track.stop())
    mediaStreamRef.current = null
  }, [])

  const getDataUri = useCallback((configDataUri: CaptureConfigOption, silent?: boolean) => {
    if (!libCameraPhotoRef.current) return null

    if (!silent) {
      shutterAudio.play()
    }
    return libCameraPhotoRef.current.getDataUri(configDataUri)
  }, [])

  useLayoutEffect(() => {
    if (videoRef && videoRef.current) {
      libCameraPhotoRef.current = new LibCameraPhoto(videoRef.current)
    }
  }, [])

  useEffect(() => {
    const fn = () => {
      if (document.hidden) {
        stopStream()
      } else {
        enableStream()
      }
    }

    document.addEventListener('visibilitychange', fn)

    let permissionStatus: PermissionStatus | null = null
    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'camera' as PermissionName }).then((status) => {
        permissionStatus = status
        permissionStatus.addEventListener('change', () => window.location.reload(), { once: true })
      })
    }
    enableStream()

    return () => {
      document.removeEventListener('visibilitychange', fn)
      if (permissionStatus) {
        permissionStatus.removeEventListener('change', () => {})
      }
      stopStream()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableStream, stopStream])

  return {
    mediaStream: mediaStreamRef.current,
    getDataUri,
    stopStream,
    enableStream,
    cameraStartError: state.cameraStartError,
    cameraState: state.cameraState,
    devices: state.devices,
    videoRef,
    currentDeviceId: state.currentDeviceId,
  }
}
