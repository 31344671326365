import { Box, Button, Group, Slider, Stack, Text } from '@mantine/core'
import { DocumentDownload } from 'iconsax-react'
import { Liquid } from 'liquidjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { numericFormatter } from 'react-number-format'
import { POCLimitFormSchemaType } from './POCLimitPlayground'
import proofOfCoverage from './proof_of_coverage.html?raw'

const engine = new Liquid()

export const POCTemplate = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const [coverageSize, setCoverageSize] = useState(16)
  const { control } = useFormContext<POCLimitFormSchemaType>()

  const globalLimit = useWatch({ control, name: 'globalLimit' })
  const annualLimit = useWatch({ control, name: 'annualLimit' })
  const transactionLimit = useWatch({ control, name: 'transactionLimit' })
  const percentage = useWatch({ control, name: 'percentage' })
  const cashAdvance = useWatch({ control, name: 'cashAdvance' })
  const coverageType = useWatch({ control, name: 'coverageType' })

  const items = useWatch({ control, name: 'items' })

  const getCoverableAmount = useCallback(
    (
      item: POCLimitFormSchemaType['items'][number],
      globalLimit?: number,
      annualLimit?: number,
      transactionLimit?: number,
    ) => {
      const limits: {
        type: string
        amount: number
        label: string
      }[] = []
      if (item.excluded) return { coverableAmount: 0, limits }
      let coverableAmount = item.pricePerUnit * item.quantity

      if (typeof item.limitPerUnit === 'number' && item.pricePerUnit > item.limitPerUnit) {
        coverableAmount = item.limitPerUnit * item.quantity
        limits.push({ type: 'limitPerUnit', amount: item.limitPerUnit, label: 'Limit per unit' })
      }
      if (
        coverageType === 'other' &&
        typeof item.limitPerTransaction === 'number' &&
        coverableAmount > item.limitPerTransaction
      ) {
        coverableAmount = item.limitPerTransaction
        limits.push({ type: 'limitPerTransaction', amount: item.limitPerTransaction, label: 'Limit per transaction' })
      }

      if (coverageType !== 'other' && typeof transactionLimit === 'number' && coverableAmount > transactionLimit) {
        coverableAmount = transactionLimit
        limits.push({ type: 'transactionLimit', amount: transactionLimit, label: 'Transaction limit' })
      }

      if (coverageType === 'other' && typeof item.annualLimit === 'number' && coverableAmount > item.annualLimit) {
        coverableAmount = item.annualLimit
        limits.push({ type: 'annualLimit', amount: item.annualLimit, label: 'Annual limit' })
      }

      if (coverageType !== 'other' && typeof annualLimit === 'number' && coverableAmount > annualLimit) {
        coverableAmount = annualLimit
        limits.push({ type: 'annualLimit', amount: annualLimit, label: 'Annual limit' })
      }

      if (typeof globalLimit === 'number' && coverableAmount > globalLimit) {
        coverableAmount = globalLimit
        limits.push({ type: 'globalLimit', amount: globalLimit, label: 'Global limit' })
      }

      return { coverableAmount, limits }
    },
    [coverageType],
  )

  const getFormattedNumber = (value: number) => {
    if (isNaN(value) || value < 1) return '0'

    return numericFormatter(value?.toFixed(), {
      thousandSeparator: ' ',
    })
  }

  useEffect(() => {
    if (!iframeRef.current) return

    let updatedGlobalLimit = typeof globalLimit === 'number' ? globalLimit : undefined
    let updatedAnnualLimit = coverageType !== 'other' ? annualLimit : undefined
    let updatedTransactionLimit = coverageType !== 'other' ? transactionLimit : undefined

    let totalAmount = 0
    let totalCoverableAmount = 0

    const filteredItems = items
      .filter((item) => item.name)
      .map((item) => {
        const totalPrice = item.quantity * item.pricePerUnit
        const { coverableAmount: rawCoverableAmount, limits } = getCoverableAmount(
          item,
          updatedGlobalLimit,
          updatedAnnualLimit,
          updatedTransactionLimit,
        )

        const coverableAmount = rawCoverableAmount * (percentage / 100)
        const exceededAmount = totalPrice - coverableAmount

        if (updatedGlobalLimit) {
          updatedGlobalLimit = Math.max(0, updatedGlobalLimit - coverableAmount)
        }
        if (updatedAnnualLimit) {
          updatedAnnualLimit = Math.max(0, updatedAnnualLimit - coverableAmount)
        }
        if (updatedTransactionLimit) {
          updatedTransactionLimit = Math.max(0, updatedTransactionLimit - coverableAmount)
        }

        const ceilingText = `${item.limitPerUnit ? getFormattedNumber(item.limitPerUnit) : ''}${item.unitName ? `/${item.unitName}` : ''}`

        totalAmount += totalPrice
        totalCoverableAmount += coverableAmount

        return {
          ...item,
          amount: getFormattedNumber(totalPrice),
          coverable: getFormattedNumber(coverableAmount),
          exceeded: getFormattedNumber(exceededAmount),
          percentage: `${percentage}%`,
          ceiling: ceilingText,
          limit: { ...limits.slice(-1)[0], service: item.name, rate: percentage },
        }
      })

    const insurancePay = cashAdvance ? totalAmount : totalCoverableAmount
    const remainingAmount = totalAmount - totalCoverableAmount

    const renderedHtml = engine.parseAndRenderSync(proofOfCoverage, {
      remSize: `${coverageSize}px`,
      claimTotal: getFormattedNumber(totalAmount),
      totalCoverableAmount: getFormattedNumber(totalCoverableAmount),
      insurancePay: getFormattedNumber(insurancePay),
      remainingAmount: getFormattedNumber(remainingAmount),
      hasExceededAmount: totalAmount > totalCoverableAmount,
      percentage: percentage > 0 ? percentage.toFixed() : 0,
      cashAdvance,
      coverageType,
      items: filteredItems,
      limits: filteredItems.filter((item) => item.limit.type).map((item) => item.limit),
    })

    const doc = iframeRef.current.contentDocument

    if (doc) {
      if (import.meta.env.DEV) {
        iframeRef.current.setAttribute('srcDoc', renderedHtml)
        return
      }
      doc.open()
      doc.write(renderedHtml)
      doc.close()
    }

    return () => {
      doc?.open()
      doc?.write('')
      doc?.close()
    }
  }, [
    cashAdvance,
    coverageSize,
    coverageType,
    globalLimit,
    annualLimit,
    transactionLimit,
    items,
    percentage,
    getCoverableAmount,
  ])

  const downloadPdf = () => {
    const iframe = iframeRef.current
    if (!iframe) return

    const doc = iframe.contentDocument
    if (!doc) return

    const printWindow = window.open('', '', 'height=768,width=1024')
    printWindow?.document.write(doc.documentElement.outerHTML)
    printWindow?.document.close()
    printWindow?.print()
  }

  return (
    <Stack h={'100%'} pos="relative">
      <iframe
        ref={iframeRef}
        allowTransparency={true}
        className="flex-1 border-none ring-1 ring-gray-200 rounded-lg bg-white shadow-lg "
      />
      <Group justify="space-between">
        <Box>
          <Text size="sm">Font size</Text>
          <Slider w={200} min={12} max={24} value={coverageSize} onChange={(value) => setCoverageSize(value)} />
        </Box>
        <Button variant="light" color="alerts-green" leftSection={<DocumentDownload />} onClick={downloadPdf}>
          Download as PDF
        </Button>
      </Group>
    </Stack>
  )
}
